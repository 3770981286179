import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "blog-post" }
const _hoisted_2 = { class: "post-container" }
const _hoisted_3 = { class: "meta" }
const _hoisted_4 = { class: "post-container" }
const _hoisted_5 = { class: "flex-hgap" }
const _hoisted_6 = ["datetime"]
const _hoisted_7 = {
  ref: "contentRef",
  class: "post-container text-container"
}

import { computed } from 'vue'
import { useSeoMeta } from '@unhead/vue'
import { formatDate, formatDateDisplay } from '@/common/utils/formatDate'

type DateInfo = {
    date: string
    label: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogPost',
  props: {
    title: {},
    image: {},
    createdAt: {},
    updatedAt: {}
  },
  setup(__props: any) {

const props = __props

useSeoMeta({
    title: props.title,
    ogImage: props.image,
    twitterCard: 'summary_large_image',
})

const dateInfos = computed<Array<DateInfo>>(() => {
    const dateInfos: Array<DateInfo> = [
        {
            date: formatDate(props.createdAt),
            label: `Published on ${formatDateDisplay(props.createdAt)}`,
        },
    ]

    if (props.updatedAt !== undefined) {
        dateInfos.push({
            date: formatDate(props.updatedAt),
            label: `Updated on ${formatDateDisplay(props.updatedAt)}`,
        })
    }

    return dateInfos
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", {
      class: "hero-unit",
      style: _normalizeStyle({
                'background-image': _ctx.image ? `url('${_ctx.image}')` : undefined
            })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["title", {
                    'has-image': Boolean(_ctx.image),
                }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1 /* TEXT */)
        ])
      ], 2 /* CLASS */),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("aside", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dateInfos.value, (dateInfo) => {
              return (_openBlock(), _createElementBlock("time", {
                key: dateInfo.date,
                datetime: dateInfo.date
              }, _toDisplayString(dateInfo.label), 9 /* TEXT, PROPS */, _hoisted_6))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ])
    ], 4 /* STYLE */),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "default")
    ], 512 /* NEED_PATCH */)
  ]))
}
}

})