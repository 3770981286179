import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import App from './App.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AppLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      _createVNode(App)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})