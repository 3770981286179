import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "title", "alt"]
const _hoisted_2 = { key: 0 }

import mediumZoom from 'medium-zoom'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { ResponsiveLoaderAsset } from '@/web/client/utils/ResponsiveLoaderAsset'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleImage',
  props: {
    img: {},
    title: { default: '' },
    alt: { default: '' },
    aspectRatio: { default: 1.618 },
    enableZoom: { type: Boolean, default: true },
    enableBorder: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props

// Set up intersection observer
const containerRef = ref<HTMLDivElement | null>(null)
const hasScrolledIntoView = ref(false)
let observer: IntersectionObserver | null = null
onMounted(() => {
    if (!containerRef.value) {
        throw new Error('Cannot find containerRef')
    }
    if (!(containerRef.value instanceof Element)) {
        throw new Error(`containerRef is not an Element: ${typeof containerRef.value}`)
    }

    observer = new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) {
            return
        }

        hasScrolledIntoView.value = true
        observer?.disconnect()
    })
    observer.observe(containerRef.value)
})
onBeforeUnmount(() => {
    observer?.disconnect()
})

const imageRef = ref<HTMLImageElement | null>(null)
const onImageLoadSuccess = () => {
    if (!imageRef.value) {
        throw new Error('Cannot find imageRef')
    }

    if (props.enableZoom) {
        mediumZoom(imageRef.value)
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("figure", {
    ref_key: "containerRef",
    ref: containerRef,
    class: _normalizeClass({
            border: _ctx.enableBorder,
        })
  }, [
    _createElementVNode("picture", null, [
      (hasScrolledIntoView.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            ref_key: "imageRef",
            ref: imageRef,
            src: _ctx.img.src,
            title: _ctx.title,
            alt: _ctx.alt ?? _ctx.title,
            loading: "lazy",
            referrerpolicy: "no-referrer",
            onLoad: onImageLoadSuccess
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("figcaption", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})