import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

import { ref, watch, onMounted } from 'vue'
import { sleep } from '@/common/utils/sleep'


export default /*@__PURE__*/_defineComponent({
  __name: 'CodeBlock',
  props: {
    code: {},
    language: { default: 'txt' },
    popout: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props

const highlightedCode = ref<string>()
watch(() => props, async() => {
    const { codeToHtml } = await import('shiki')
    highlightedCode.value = await codeToHtml(props.code, {
        lang: props.language,
        theme: 'github-light',
    })
}, {
    immediate: true,
    deep: true,
})

const clipboardEnabled = ref(false)
onMounted(() => {
    clipboardEnabled.value = 'clipboard' in navigator
})

const showCheckmark = ref(false)
async function copyToClipboard() {
    await navigator.clipboard.writeText(props.code)

    // Update icon temporarily
    showCheckmark.value = true
    await sleep(3000)
    showCheckmark.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'code-block': true,
            popout: _ctx.popout,
        })
  }, [
    (clipboardEnabled.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          title: "Copy code to clipboard",
          onClick: copyToClipboard
        }, [
          (showCheckmark.value)
            ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 0,
                name: "done"
              }))
            : (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 1,
                name: "copy"
              }))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", { innerHTML: highlightedCode.value }, null, 8 /* PROPS */, _hoisted_1)
  ], 2 /* CLASS */))
}
}

})