import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, h, useSlots } from 'vue'
import { useRoute } from 'vue-router'
import { slugify } from '@/common/utils/slugify'
import { getIconSvgRaw } from '@/web/client/utils/ResponsiveLoaderAsset'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextHeading',
  props: {
    size: { default: 2 },
    disableLink: { type: Boolean }
  },
  setup(__props: any) {

const slots = useSlots()
const props = __props

const route = useRoute()
const enableLink = computed(() => !props.disableLink && props.size <= 3) // h3 or lower
const render = () => {
    const childNodes = slots.default?.()
    const slug = (() => {
        let text = ''
        for (const node of childNodes ?? []) {
            if (typeof node.children !== 'string') {
                continue
            }

            text += node.children
        }

        if (text.length === 0) {
            throw new Error('Failed to compute slug for TextHeading')
        }

        return slugify(text)
    })()

    return h(
        `h${props.size}`,
        {
            id: slug,
            class: 'linked-heading',
        },
        [
            enableLink.value && h(
                'a',
                {
                    href: `${route.path}#${slug}`,
                    title: 'Link to this section',
                },
                [
                    childNodes,
                    h(
                        'div',
                        {
                            class: 'svg',
                            innerHTML: getIconSvgRaw('link'),
                        },
                    ),
                ],
            ),
        ],
    )
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(render))
}
}

})