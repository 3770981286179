import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { getIconSvgRaw } from '../utils/ResponsiveLoaderAsset'


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
    name: {},
    isDark: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            ['svg-wrapper']: true,
            [_ctx.name]: true,
            dark: _ctx.isDark,
        }),
    innerHTML: _unref(getIconSvgRaw)(_ctx.name)
  }, null, 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})