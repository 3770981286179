import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "simple-table" }

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleTable',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props

const theadRow = computed(() => props.data[0])
const tbodyRows = computed(() => [...props.data].splice(1))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(theadRow.value.entries(), ([colIdx, col]) => {
            return (_openBlock(), _createElementBlock("td", { key: colIdx }, _toDisplayString(col), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tbodyRows.value.entries(), ([rowIdx, row]) => {
          return (_openBlock(), _createElementBlock("tr", { key: rowIdx }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.entries(), ([colIdx, col]) => {
              return (_openBlock(), _createElementBlock("td", { key: colIdx }, _toDisplayString(col), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}
}

})